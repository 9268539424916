import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)


// 阻止多次点击的时候报错
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

export const constantRoutes = [
    // {
    //     path: '*',
    //     redirect: '/'
    // },
    // 一级
    {
        path: '/',
        name: 'TopHeader',
        component: () =>
            import('@/views/topheader/topheader.vue'),
        redirect: '/home',
        children: [
            {
                path: 'home',
                name: 'Home',
                component: () =>
                    import('@/views/home'),
                redirect: '/home/dataStatistics',
                meta: { title: '首页' },
                children: [
                    {
                        path: '/home/dataStatistics',
                        name: 'dataStatistics',
                        component: () =>
                            import('@/views/home/components/dataStatistics'),
                        meta: { title: '数据统计' },

                    },
                    {
                        path: '/home/incomeStatistics',
                        name: 'incomeStatistics',
                        component: () =>
                            import('@/views/home/components/incomeStatistics'),
                        meta: { title: '收入统计' },

                    },
                    {
                        path: '/home/expenditureStatistics',
                        name: 'expenditureStatistics',
                        component: () =>
                            import('@/views/home/components/expenditureStatistics'),
                        meta: { title: '支出统计' },

                    },
                    {
                        path: '/home/income',
                        name: 'income',
                        component: () =>
                            import('@/views/home/income'),
                        meta: { title: '门店列表' },

                    },
                    {
                        path: '/home/expenditure',
                        name: 'expenditure',
                        component: () =>
                            import('@/views/home/expenditure'),
                        meta: { title: '门店列表' },

                    },
                    {
                        path: '/home/incomenew',
                        name: 'incomenew',
                        component: () =>
                            import('@/views/home/components/incomenew'),
                        meta: { title: '详情' },

                    },
                    {
                        path: '/home/expenditurenew',
                        name: 'expenditurenew',
                        component: () =>
                            import('@/views/home/components/expenditurenew'),
                        meta: { title: '详情' },

                    },
                    {
                        path: '/home/flowing',
                        name: 'flowing ',
                        component: () =>
                            import('@/views/home/flowing'),
                        meta: { title: '门店流水表' },

                    },
                    {
                        path: '/home/profit',
                        name: 'profit ',
                        component: () =>
                            import('@/views/home/profit'),
                        meta: { title: '门店利润表' },

                    },
                    {
                        path: '/home/quantity',
                        name: 'quantity ',
                        component: () =>
                            import('@/views/home/quantity'),
                        meta: { title: '订单数量表' },

                    },
                    {
                        path: '/home/Guest',
                        name: 'Guest ',
                        component: () =>
                            import('@/views/home/Guest'),
                        meta: { title: '客单价' },

                    },
                    {
                        path: '/home/ordertype',
                        name: 'ordertype ',
                        component: () =>
                            import('@/views/home/ordertype'),
                        meta: { title: '订单类型占比' },

                    },
                    {
                        path: '/home/channel',
                        name: 'channel ',
                        component: () =>
                            import('@/views/home/channel'),
                        meta: { title: '渠道占比' },

                    },
                ]
            },
            {
                path: 'refund',
                name: 'refund',
                component: () =>
                    import('@/views/refund'),
                meta: { title: '部分退款' },

            },
            {
                path: 'order',
                name: 'order',
                component: () =>
                    import('@/views/order'),
                meta: { title: '订单管理' },

            },
            {
                path: '/order/orderinput',
                name: 'orderinput',
                component: () =>
                    import('@/views/order/components/orderinput'),
                meta: { title: '录入订单' },

            },
            {
                path: '/order/orderinfo',
                name: 'orderinfo',
                component: () =>
                    import('@/views/order/components/orderinfo'),
                meta: { title: '详情' },

            },
            {
                path: 'staff',
                name: 'staff',
                component: () =>
                    import('@/views/staff'),
                meta: { title: '员工管理首页' },
                redirect: '/staff/stafflist',
                children: [
                    {
                        path: '/staff/stafflist',
                        name: 'stafflist',
                        component: () =>
                            import('@/views/staff/stafflist'),
                        meta: { title: '员工列表' },

                    },
                    {
                        path: '/staff/stafflistedit',
                        name: 'stafflistedit',
                        component: () =>
                            import('@/views/staff/components/staffedit'),
                        meta: { title: '编辑员工' },

                    },
                    {
                        path: '/staff/stafflistinfo',
                        name: 'stafflistinfo',
                        component: () =>
                            import('@/views/staff/components/staffinfo'),
                        meta: { title: '查看员工详情' },

                    },
                    {
                        path: '/staff/staffwork',
                        name: 'staffwork',
                        component: () =>
                            import('@/views/staff/staffwork'),
                        meta: { title: '工作日历' },

                    },
                    {
                        path: '/staff/staffworkinfo',
                        name: 'staffworkinfo',
                        component: () =>
                            import('@/views/staff/components/staffworkinfo'),
                        meta: { title: '工作详情' },

                    },
                    {
                        path: '/staff/staffcheck',
                        name: 'staffcheck',
                        component: () =>
                            import('@/views/staff/staffcheck'),
                        meta: { title: '考勤统计' },

                    },
                    {
                        path: '/staff/staffleave',
                        name: 'staffleave',
                        component: () =>
                            import('@/views/staff/staffleave'),
                        meta: { title: '请假审批' },

                    },
                    {
                        path: '/staff/staffmoney',
                        name: 'staffmoney',
                        component: () =>
                            import('@/views/staff/staffmoney'),
                        meta: { title: '佣金记录' },

                    },
                    {
                        path: '/staff/staffmoneyinfo',
                        name: 'staffmoneyinfo',
                        component: () =>
                            import('@/views/staff/components/staffmoneyinfo'),
                        meta: { title: '佣金详情' },

                    },
                ]
            },
            {
                path: 'vip',
                name: 'vip',
                component: () =>
                    import('@/views/vip'),
                meta: { title: '会员管理' },


            },
            {
                path: '/vip/vipinfo',
                name: 'vipinfo',
                component: () =>
                    import('@/views/vip/components/vipinfo'),
                meta: { title: '会员信息' },

            },
            {
                path: '/vip/vipxfrecords',
                name: 'vipxfrecords',
                component: () =>
                    import('@/views/vip/components/vipxfrecords'),
                meta: { title: '消费记录' },

            },
            {
                path: '/vip/vipczrecords',
                name: 'vipczrecords',
                component: () =>
                    import('@/views/vip/components/vipczrecords'),
                meta: { title: '充值记录' },

            },


            {
                path: 'stock',
                name: 'stock',
                component: () =>
                    import('@/views/stock'),
                meta: { title: '库存管理' },
                redirect: '/stock/stockstore',
                children: [
                    {
                        path: '/stock/stockstore',
                        name: 'stockstore',
                        component: () =>
                            import('@/views/stock/stockstore'),
                        meta: { title: '门店库存' },

                    },
                    {
                        path: '/stock/stockstorelist',
                        name: 'stockstorelist',
                        component: () =>
                            import('@/views/stock/components/stockstorelist'),
                        meta: { title: '库存管理' },

                    },
                    {
                        path: '/stock/stockstorelistenter',
                        name: 'stockstorelistenter',
                        component: () =>
                            import('@/views/stock/components/stockstorelistenter'),
                        meta: { title: '入库管理' },

                    },
                    {
                        path: '/stock/stockstorelistout',
                        name: 'stockstorelistout',
                        component: () =>
                            import('@/views/stock/components/stockstorelistout'),
                        meta: { title: '出库管理' },

                    },
                    {
                        path: '/stock/stockstoretj',
                        name: 'stockstoretj',
                        component: () =>
                            import('@/views/stock/components/stockstoretj'),
                        meta: { title: '库存统计' },

                    },
                    {
                        path: '/stock/stockpurchase',
                        name: 'stockpurchase',
                        component: () =>
                            import('@/views/stock/stockpurchase'),
                        meta: { title: '采购申请' },

                    },
                    {
                        path: '/stock/stockpurchaseapply',
                        name: 'stockpurchaseapply',
                        component: () =>
                            import('@/views/stock/components/stockpurchaseapply'),
                        meta: { title: '新建采购' },

                    },
                    {
                        path: '/stock/stockpurchasesee',
                        name: 'stockpurchasesee',
                        component: () =>
                            import('@/views/stock/components/stockpurchasesee'),
                        meta: { title: '查看详情' },

                    },


                ]

            },
            {
                path: 'operate',
                name: 'operate',
                component: () =>
                    import('@/views/operate'),
                meta: { title: '运营管理' },
                redirect: '/operate/operateacnum',
                children: [
                    {
                        path: '/operate/operatebanner',
                        name: 'operatebanner',
                        component: () =>
                            import('@/views/operate/operatebanner'),
                        meta: { title: 'banner管理' },

                    },
                    {
                        path: '/operate/refuseTimes',
                        name: 'refuseTimes',
                        component: () =>
                            import('@/views/operate/refuseTimes'),
                        meta: { title: '拒单时间' },

                    },
                    {
                        path: '/operate/operateacnum',
                        name: 'operateacnum',
                        component: () =>
                            import('@/views/operate/operateacnum'),
                        meta: { title: '账号管理' },

                    },
                ]

            }
        ]


    },
    {
        path: '/login',
        name: 'login',
        component: () =>
            import('@/views/login/index'),
    },
    {
        path: '*',
        name: '404',
        component: () =>
            import('@/views/404.vue'),
    },
]

const createRouter = () => new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
})

const router = createRouter()

export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

export default router
