const urls = {
  // 登陆
  loginInfo: {
    login: "/api/login/door", //登陆
    sendCode: "/api/sms/sendSms" //获取验证码
  },
  // 字典
  dict: {
    list: "/api/dict/list/byTypes"
  },
  // 员工信息
  staffInfo: {
    staffleave: "/api/leaveInfo/ask/list", //请假列表
    approve: "/api/leaveInfo/ask/approve", //请假审批
    staffleaveinfo: "/api/leaveInfo/info", //请假详情
    staffcheck: "/api/attendanceRecord/shop/total", //考勤统计
    userlist: "/api/userInfo/list", //员工列表
    userlistInfo: "/api/userInfo/info", //用户详情
    userlistedit: "/api/userInfo/edit", //修改员工信息
    userlistsetpwd: "/api/userInfo/updatePassWord", //修改员工密码
    userlistgetjl: "/api/serverResume/user/info", //用户简历
    editUserState: "/api/userInfo/orderTaking", //修改接单状态
    ShopCalendar: "/api/work/page", //工作日历
    ShopCalendarinfo: "/api/work/info/page", //工作日历详情
    money: "/api/commission/commissionList", //佣金记录
    moneyinfo: "/api/commission/commissionList/detail", //佣金详情
  },
  // 库存管理
  stockInfo: {
    purchase: "/api/purchaseApply/page", //采购申请列表
    purchaseinfo: "/api/purchaseApply/info", //采购申请列表详情
    storelist: "/api/shopInfo/doorPage", //门店所有列表
    doorPageManage: "/api/shopInfo/doorPage/manage", //门店所有列表
    assetsInfoAllType: "/api/assetsInfo/all/type", //门店所有列表
    assetsInfoAssetsInfo: "/api/assetsInfo/all/assetsInfo", //门店所有列表
    stockapply: "/api/purchaseApply/add", //采购申请
    stockApplyIn: "/api/purchaseApply/in", //入库申请
    store: "/api/assetManage/page/shopTotal", //门店库存列表
    storeadmin: "/api/assetManage/page", //库存管理
    storeadminList: "/api/stockManage/page", //库存管理
    storeeditinfo: "/api/assetManage/edit", //更新库存
    assetslist: "/api/assetsInfo/page", //资产列表
    assetsnewlist: "/api/assetsInfo/page/shop", //资产列表新
    storeoutadd: "/api/stockManage/add/out", //新建出库
    storeenteradd: "/api/stockManage/add/in", //新建入库
    storeStaList: "/api/assetManage/page/shopTotal/stock", //门店统计
    manageStorelist:"/api/shopInfo/doorPage/manage" //所有管理门店
  },
  // 运营管理
  operateInfo: {
    operatebanner: "/api/banner/page", //轮播图
    operatenewbanner: "/api/banner/add", //新建轮播图
    operateeditbanner: "/api/banner/edit", //修改轮播图
    getbannermation: "/api/information/page", //资讯
    getshoplist: "/api/goodsCategory/all", //获取商品分类
    getshopinfo: "/api/goodsInfo/page", //获取商品
    getshoplistinfo: "/api/goodsInfo/info", //获取商品详情
    disopban: "/api/banner/avl", //禁用banner
    delbanner: "/api/banner/delete", //删除banner
    userinfo: "/api/userInfo/info/my", //当前用户信息
    usersetpassword: "/api/userInfo/resetPassWord", //修改密码
    refuseTimes:"/api/shopTime/page", //门店拒单时间
    deleteRefuseTime:"/api/shopTime/delete", //门店拒单删除
    addRefuseTimePoint:"/api/shopTime/add", //门店拒单添加
    addRefuseTimeScope:"/api/shopTime/add/batch",//门店拒单批量添加
    delRefuseTimeScope:"/api/shopTime/del/batch" //门店拒单批量删除
  },
  refund: {
    partialRefundRecord: "api/partialRefundRecord/page", //部分退款
    maxRefundAmount: "/api/refundBiz/maxRefundAmount", //部分退款
  },
  // 订单管理
  orderInfo: {
    orderlist: "/api/door/order/orderList", //订单列表
    orderaddlist: "/api/door/order/saveOrder", // 录入订单
    orderdistribution: "/api/door/order/dispatchOrder", // 订单分配
    orderuserlist: "/api/userInfo/list/order", // 排单员工列表
    orderTransfer: "/api/door/order/modifyShop", //转店
    orderpaymenthod: "/api/orderChannel/all", //渠道
    orderlistinfo: "/api/door/order/findOrderDetail", //订单详情
    addorderphoto: "/api/door/order/addInvitePicture", // 上传回访照片多张
    orderDividingmoney: "/api/door/order/findCommission", // 调整分佣弹窗
    orderDividingmoneySubmer: "/api/door/order/modifyCommission", //调整分佣提交
    delorderphoto: "/api/door/order/delInvitePictureSingle", //删除回访照片
    Chargeback: "/api/refundBiz/refund",
    returnVisitDetail: "/api/door/order/infoReturn",
    addReturnVisit: "/api/door/order/addReturn",     // 添加回访记录的详情
    deleteVisitRecord: "/api/door/order/delReturn",
    finishOrder: "/api/order/webFinishOrder",
    updateServiceTime: "/api/order/updateServiceTime",
    updateOrderInfo: "/api/order/modifyOrderInfo"

  },
  // 会员信息
  vipinfo: {
    viplist: "/api/member/memberList", //会员列表
    viplistinfo: "/api/member/memberInfo", //会员详情
    vipinfoecharts: "/api/member/memberData", //详情曲线图
    vipinfocont: "/api/member/addInviteRecord", //填写回访记录
    vipinfoconsumption: "/api/member/consumeRecord", //消费记录
    vipinfoRecharge: "/api/member/chargeRecord", //充值记录
  },
  // 首页
  homeinfo: {
    BusinessStatistics: "/api/statistics/saleTotal", //营业信息统计
    CustomerStatistics: "/api/statistics/customTotal", //客户统计信息
    Revenueexpenditure: "/api/statistics/total/fast", //收支数据统计
    ServiceType: "/api/statistics/categoryStatistic", //服务类型统计
    Channelstatistics: "/api/statistics/channelRate", //渠道占比
    Ordert: "/api/statistics/orderStatistic", //订单统计
    Incomestatistics: "/api/shopRevenue/total/in", //收入统计
    Incomestatisticsinfo: "/api/shopRevenue/page", //收入详情
    incomeType: "/api/revenueType/all", // 收入类型
    incomeType1: "/api/revenueType/all/in", // 收入类型
    expendType: "/api/revenueType/all/out", // 支出类型
    Incomestatisticsnew: "/api/shopRevenue/add", //添加支出
    Expenditurestatistics: "/api/shopRevenue/total/out", //支出统计
    Storeflow: "/api/statistics/shopFlow", //门店流水
    Storeprofit: "/api/statistics/shopProfit", //门店利润
    OrderNum: "/api/statistics/oderNum", // 订单数量
    Customerunitprice: "/api/statistics/singlePrice", //客单价
    ordertype: "/api/statistics/categoryRateTable", //订单类型占比表格
    ordertypechart: "/api/statistics/categoryRatePie", //订单类型占比图表
    channel: "/api/statistics/channelRateTable", //渠道占比表格
    channelechart: "/api/statistics/channelRatePie", //渠道占比图表

  }

};


export default {
  install: function (Vue, Option) {
    Object.defineProperty(Vue.prototype, "$urls", {
      value: urls
    });
  },
  urls

};
