<template>
  <div>
    <el-button
      @click="autoPlay"
      type="text"
      :style="{ color: isPlay ? 'red' : '#05a6e5' }"
      size="small"
      >{{ isPlay ? "暂停" : "播放" }}</el-button
    >
  </div>
</template>

<script>
export default {
  props: {
    recordFile: {
      type: String,
    },
  },
  data() {
    return {
      isPlay: false,
      myAuto: new Audio(this.recordFile),
    };
  },
  methods: {
    autoPlay() {
      this.isPlay = !this.isPlay;
      if (this.isPlay) {
        this.myAuto.play();
        this.palyEnd();
      } else {
        this.myAuto.pause();
        this.palyEnd();
      }
    },
    palyEnd() {
      this.myAuto.addEventListener("ended", () => {
        this.isPlay = false;
      });
    },
  },
};
</script>

<style>
</style>