<template>
  <div>
    <el-upload
      v-if="upLoadtype != 'buttonUpLoad'"
      class="avatar-uploader"
      :list-type="listtype"
      :file-list="fileList"
      action=""
      :on-preview="handlePictureCardPreview"
      :http-request="uploadFile"
      :show-file-list="showList"
      :before-upload="beforeAvatarUpload"
      :on-remove="beforeRemove"
      :multiple="multiple"
      ref="uploadList"
    >
      <img v-if="imageUrl" :src="imageUrl" class="avatar" />
      <i
        v-else
        :class="
          listtype ? 'el-icon-plus ' : 'el-icon-plus avatar-uploader-icon'
        "
      ></i>
      <!-- <i v-if="!progressFlag" class="el-icon-plus"></i>
      <el-progress
        type="circle"
        v-else
        :percentage="loapercentage"
        style="
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        "
        :width="50"
      ></el-progress> -->
    </el-upload>
    <el-upload
      v-else
      class="noneStyle"
      :list-type="listtype"
      action=""
      :on-preview="handlePictureCardPreview"
      :http-request="uploadFile"
      :show-file-list="showList"
      :before-upload="beforeAvatarUpload"
      :on-remove="beforeRemove"
      :multiple="multiple"
      ref="uploadList"
    >
      <el-button size="small" type="primary">上传</el-button>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import OSS from "ali-oss";

export default {
  name: "CodingUpload",
  components: {},
  data() {
    return {
      progressFlag: false, // 关闭进度条,
      loapercentage: 0,
      dialogImageUrl: "",
      dialogVisible: false,
      fileList: [],
      imageUrl: "",
    };
  },
  props: {
    editForm: Object,
    buttonName: String,
    showList: {
      type: Boolean,
      default: false,
    },
    listtype: {
      type: String,
      default: "",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    upLoadtype: {
      type: String,
      default: "",
    },
  },
  created() {
    console.log(this.upLoadtype, "upLoadtypeupLoadtype");
  },
  methods: {
    // 自定义上传 不能删
    uploadFile() {},
    // 文件上传时
    handlePictureCardPreview(file) {
      console.log("打开了");
      console.log(this.imageUrl);
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 调用oss换取路径
    uploadToServer(file) {
      let _this = this;
      console.log(this.$refs.uploadList);
      this.progressFlag = true;
      const formData = new FormData();
      formData.append("name", file.name);
      formData.append("file", file);

      let url = "/api/file/ossRoleResponse";

      this.$api.get(url, { params: { filename: file.name } }).then((res) => {
        let data = res.data;
        const client = new OSS({
          region: data.region,
          accessKeyId: data.accessKeyId,
          accessKeySecret: data.accessKeySecret,
          bucket: data.bucketName,
          stsToken: data.securityToken,
        });

        //带进度条的上传
        client
          .multipartUpload(data.fileName, file, {
            // 进度条的配置项
            progress: function (percentage) {
              // 获取进度条的值
              this.loapercentage = percentage * 100;
              // _this.loapercentage = +(percentage * 100).toFixed(2)
            },
            // 每传输完一个分片 进度条会走一个百分比 不设置的话由于partsize过大 可能导致很长时间进度条都看不到效果
            partSize: 102400, // 指定上传的每个分片的大小，范围为100 KB~5 GB。单个分片默认大小为1 * 1024 * 1024（即1 MB）
          })
          .then((result) => {
            if (result.res.status === 200) {
              let url = result.name; //上传半路径
              let urlall = data.fileBase + result.name;
              if (!this.listtype) {
                this.imageUrl = urlall;
              }
              this.$emit("success", url, urlall, file.name, this.buttonName);
              this.$forceUpdate();
              this.progressFlag = false;
              return result.url;
            }
          })
          .catch(function (err) {
            console.error("error: ", err);
          });
      });
    },
    // 文件上传之前
    beforeAvatarUpload(file) {
      const fileTypeName = this.getFileType(file.name);
      const picMax = file.size / 1024 / 1024 < 10;
      if (fileTypeName === "image") {
        this.filetype = 2;
        if (!picMax) {
          this.$message.error("上传图片大小不能超过 10MB!");
          return false;
        }
        this.uploadToServer(file);
      } else if (fileTypeName === "video") {
        this.filetype = 1;
        // 上传至云上
        this.uploadToServer(file);
      } else {
        this.filetype = 3;
        this.uploadToServer(file);
      }
    },
    // 判断上传的文件是：image/video
    getFileType(name) {
      if (!name) return false;
      let imgType = ["gif", "jpeg", "jpg", "bmp", "png"];
      let videoType = [
        "avi",
        "wmv",
        "mkv",
        "mp4",
        "mov",
        "rm",
        "3gp",
        "flv",
        "mpg",
        "rmvb",
      ];
      if (
        RegExp("\\.(" + imgType.join("|") + ")$", "i").test(name.toLowerCase())
      ) {
        return "image";
      } else if (
        RegExp("\\.(" + videoType.join("|") + ")$", "i").test(
          name.toLowerCase()
        )
      ) {
        return "video";
      } else {
        return false;
      }
    },
    beforeRemove(file, fileList) {
      this.$emit("delimg", file, fileList);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.noneStyle {
  height: 40px;
  .el-upload {
    background: #fff;
    border: none;
    height: 32px;
    line-height: 32px;
    // width: calc(100% - 0px);
    float: right;

    button {
      float: right;
      background-color: rgb(5, 166, 229);
    }
  }
}

/deep/.el-upload {
  position: relative;
}
/deep/.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
/deep/.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

/* 直接禁用 loading 元素*/
/deep/.el-upload-list__item.is-ready,
.el-upload-list__item.is-uploading {
  display: none !important;
}
/deep/.el-upload-list__item.is-ready {
  display: none !important;
}
/deep/.el-upload-list__item {
  transition: none;
}
</style>
