<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
@import "./utils/reset.css";
@import "./utils/style.css";
#app {
  height: 100%;
  overflow: hidden;
}
</style>  
