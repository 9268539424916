import Pagination from './Pagination'
import UpLoad from './upLoad'
import AudioPlay from './audioPlay.vue'

export default {
    install: function (Vue) {
        // 在自定义的插件中注册为全局组件
        Vue.component('Pagination', Pagination)
        Vue.component('UpLoad', UpLoad)
        Vue.component('AudioPlay', AudioPlay)
    }
}