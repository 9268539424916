import api from '@/api'
import url from '@/api/urls'
import {
    Local
} from '@/utils/storage'


const user = {
    //  namespaced: true, //一定要开始命名空间。
    state: {
        avatar: '',
        userinfo: {},
    },

    mutations: {
        //  登陆
        SET_USERINFO: (state, userinfo) => {
            state.userinfo = userinfo
        },
    },

    actions: {
        // 登陆
        loginIn({
            commit
        }, data) {
            return new Promise((resolve, reject) => {
                api.Axios.post(url.urls.loginInfo.login, data).then(res => {
                    if (res.status == 0) {
                        //  存储用户信息 
                        Local.set('userToken', res);
                        commit('SET_USERINFO', res)
                    }
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
    }
}

export default user