import Vue from 'vue'
import App from './App.vue'


// 引入路由
import router from './router'

// 引入vuex
import store from './store'

// 挂载本地缓存
import {
  Local
} from '@/utils/storage'
Vue.prototype.$Local = Local

// 权限
import "./permission"

import myCom from '@/components'
Vue.use(myCom)



import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'

Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999999999999
  }
})

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import './utils/elementScroll.js'

import api from './api'
Vue.use(api)

import urls from './api/urls'
Vue.use(urls)

import VueAMap from "vue-amap";

Vue.use(VueAMap);

VueAMap.initAMapApiLoader({
  key: "ebc276dad3d524ad99b8f22c7d24ba70",
  plugin: [
    "AMap.Autocomplete",
    "AMap.PlaceSearch",
    "AMap.Scale",
    "AMap.OverView",
    "AMap.ToolBar",
    "AMap.MapType",
    "AMap.PolyEditor",
    "AMap.CircleEditor",
    'AMap.Geolocation',
    "AMap.Geocoder"
  ],
  // 默认高德 sdk 版本为 1.4.4
  v: "1.4.4"
});

//高德的安全密钥
window._AMapSecurityConfig = {
	securityJsCode:"ab48d0ddbbd593cb956b9a8b7e37a100" // "安全密钥",
}

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  // 挂载路由
  router,
  store
}).$mount('#app')
