import router from '@/router/index.js'
import {
    Local
} from '@/utils/storage'

// 路由拦截  
router.beforeEach((to, from, next) => {
    let user = Local.get('userToken')
    if (!user && to.path != '/login') {
        next({
            path: "/login"
        })
    } else {
        next()
    }

});