/** * 分页组件 */
<template>
  <div class="page-box" :style="{ textAlign: tac }">
    <el-pagination
      :style="{ float: position }"
      class="msg-pagination-container"
      layout="prev, pager, next,sizes,jumper"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      background
      :current-page="childMsg.currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="childMsg.pageSize"
      :total="childMsg.total"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  name: "Pagination",
  props: ["childMsg", "position", "tac"],
  data() {
    return {
      pageparm: {
        currentPage: this.childMsg.currentPage,
        pageSize: this.childMsg.pageSize,
      },
    };
  },
  created() {},
  methods: {
    handleSizeChange(val) {
      /**
       * 子传父
       * 参数1 父元素方法
       * 参数2 数据
       */
      console.log(val);
      this.pageparm.pageSize = val;
      this.$emit("callFather", this.pageparm);
    },
    handleCurrentChange(val) {
      /**
       * 子传父
       * 参数1 父元素方法
       * 参数2 数据
       */
      this.pageparm.currentPage = val;
      this.$emit("callFather", this.pageparm);
    },
  },
  mounted() {
    let pageText = document.getElementsByClassName("el-pagination__jump")[0];
    if (pageText) {
      //可能存在不显示页码的情况，所以最好判断一下避免报错~
      pageText.childNodes[0].nodeValue = "跳至";
    }
  },
};
</script>

<style>
.msg-pagination-container.is-background .el-pager li {
  background-color: #fff !important;
  color: #858585 !important;
  border: 1px solid #dcdcdc !important;
  border-radius: 6px !important;
}
.msg-pagination-container.is-background .btn-next {
  background-color: #fff !important;
  color: #858585 !important;
  border: 1px solid #dcdcdc !important;
  border-radius: 6px !important;
}
.msg-pagination-container.is-background .btn-prev {
  background-color: #fff !important;
  color: #858585 !important;
  border: 1px solid #dcdcdc !important;
  border-radius: 6px !important;
}
.msg-pagination-container.is-background .el-pager li:not(.disabled).active {
  background-color: #05a6e5 !important;
  color: #fff !important;
  border: none !important;
}
.msg-pagination-container.is-background .el-input__inner {
  border-radius: 6px !important;
}

.page-box {
  padding: 15px 0;
  width: calc(100% - 100px);
  background: #fff;
  position: fixed;
  bottom: 30px;
  left: 50px;
  z-index: 9;
  /* text-align: center; */
}
</style>
